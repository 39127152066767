// 先引入路由文件
import VueRouter from "vue-router";
import bind from "../components/bind"
import rebind from "../components/ReBind"
import Admin from "../components/Admin"
import UpdateLog from "../components/UpdateLog"
import keyCombine from "../components/keyCombine"

// 创建路由器
export default new VueRouter({
    routes: [
        {
            path: '/', // 重定向
            redirect: '/ReBind'
        },
        {
            path: '/bind',	// 路径
            component: bind // 定义组件
        },
        {
            path: '/ReBind',
            component: rebind
        },
        {
            path: '/keyCombine',
            component: keyCombine
        },
        {
            path: '/UpdateLog',
            component: UpdateLog
        },
        {
            path: '/Admin',
            name: 'Admin',
            component: Admin
        }
    ]
})