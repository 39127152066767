<template>
  <div>
    <div class="alert alert-light animate__animated animate__fadeInUpBig text-white bg-dark" v-for="datalist in data" :key="datalist.id">
      <h4 class="alert-heading">{{ datalist.v }}</h4>
      <div :v-html="contents.text" style="white-space: pre-line;" v-for="contents in datalist.content" :key="contents.text"
        :class="contents.type" role="alert">
        {{ contents.text }}
      </div>

      <hr>
      <p class="mb-0">更新时间：{{ datalist.time }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateLog',
  data() {
    return {
      data: [
        
        {
          'id':'3','time': '2022-12-23 01:56:23', 'v': '1.0.3', 'content': [
            { "type": 'text-success', 'text': '+增加了 合并卡密 可以将别的卡密时间转到一个卡密上面 可能会有点问题\n+增加了 加载中的圈圈，查询 改绑 合并都会出现加载中提示了' },
            { "type": 'text-danger', 'text': '' },
            { "type": 'text-warning', 'text': '#修复了 首次查询会刷新界面的问题\n#修复了 改绑可以改空ID的错误' },
          ]
        },
        {
          'id':'2','time': '2022-12-03 19:29:31', 'v': '1.0.2', 'content': [
            { "type": 'text-success', 'text': '+增加了 顶部日志 可以查看更新说明\n+增加了 清除卡密信息 方便保存其他卡密' },
            { "type": 'text-danger', 'text': '' },
            { "type": 'text-warning', 'text': '#小改动 现在查询框输入后的东西默认不会显示 以免截图到群里\n#小改动 现在默认页面为换绑 以免有些人找不到\n#小改动 换绑或者删除记录 会自动刷新页面' },
          ]
        },
        {
          'id':'1','time': '2022-12-03 17:33:40', 'v': '1.0.1', 'content': [
            { "type": 'text-success', 'text': '+增加了 记住卡密 现在网站可以记住你首次查询的卡密' },
            { "type": 'text-danger', 'text': '' },
            { "type": 'text-warning', 'text': '#修复了 需要绑定2次才生效的BUG' },
          ]
        },
      ]
    }
  }
}
</script>

<style>

</style>