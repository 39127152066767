<template>
  <div class="text-white">
    <div class="row">
      <div class="col-xl-12">
        <div class="form-group">
          <label class="text-white">旧卡密：</label>
          <input v-model="keyCombine.oldKey" type="email" class="form-control" maxlength="30"
            placeholder="请输入以前的的卡密">
        </div>
        <div class="form-group">
          <label class="text-white">新卡密：</label>
          <input v-model="keyCombine.newKey" type="email" class="form-control" maxlength="30"
            placeholder="请输入新的卡密">
        </div>

      </div>

      <div class="col-xl-12">
        <button class="btn btn-primary" v-if="keyCombine.button == true" @click="getKeyCombine" style="width:100%;" type="submit">合并</button>
      </div>

      <div class="col-xl-12"  v-if="keyCombine.button == false" style="display:grid; place-items: center;" >
        <div class="spinner-border" style="" role="status">
          <span class="sr-only" >Loading...</span>
        </div>
      </div>

      <div class="col-xl-12" style="margin-top:20px;">
        <div class="alert alert-primary" role="alert">
          提示：合并后将会保留旧的卡密 废除新的卡密 请妥善保管你的卡密
            <li>请勿输入过期的旧卡密</li>
            <li>请勿多次点击提交 容易废除新旧卡密</li>
            <li>新卡密必须是从未绑定过的卡密</li>
            <li>旧的卡密必须是正在使用中的卡密</li>
        </div>
      </div>
      <div class="col-xl-12" >
        <div class="alert alert-warning" role="alert">
          <b>WARNING</b> ：本功能目前可能存在一些奇葩问题，请使用之前推荐打开录制，以便出问题时提交。
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'keyCombine',
  data() {
    return {
      keyCombine:{
        oldKey:'',
        newKey:'',
        button:true
      }
    }
  },
  methods:{
    getKeyCombine: function(){
      this.keyCombine.button = false;
      
      const axios = require('axios');
      // Axios
      axios.get(`https://xg.jsip.cc/keyCombine.php?oldKey=${this.keyCombine.oldKey}&newKey=${this.keyCombine.newKey}`)
      .then(response => {
          // response.data.code
          switch( response.data.code ){
            case 200:
              window.alert(response.data.data.alert);
              this.keyCombine.button = true;
            break;
            case 403:
              window.alert("错误："+response.data.data.alert);
              this.keyCombine.button = true;
            break;
          }
      })
      .catch(function (error) {
          window.alert("傻逼用户又输入了什么几把，请反馈给棱镜，"+error);
      })
    }
  }
}
</script>

<style>

</style>