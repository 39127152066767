<template>
  <div class="text-white">
    <div class="row">
      <div class="col-xl-12">
        <div class="form-group">
          <label class="text-white">卡密：</label>
          <input v-model="rebind_cdk" type="email" class="form-control" maxlength="30"
            placeholder="请输入30位的卡密">
        </div>
        <div class="form-group">
          <label class="text-white">需要绑定的ID：</label>
          <input v-model="rebind_id" type="email" class="form-control" maxlength="30"
            placeholder="Minecraft ID">
        </div>

      </div>

      <div class="col-xl-12">
        <button class="btn btn-primary" @click="requery" v-if="rebind_load == true" style="width:100%;" type="submit">{{ rebind_button }}</button>
        <div class="col-xl-12"  v-if="rebind_load == false" style="display:grid; place-items: center;" >
          <div class="spinner-border" style="" role="status">
            <span class="sr-only" >Loading...</span>
          </div>
        </div>
        <button class="btn btn-danger" @click="deleteKey" style="width:100%; margin-top:15px;"
          type="submit">删除本地保存卡密</button>
      </div>

      <div class="col-xl-12" style="margin-top:20px;">
        <div class="alert alert-primary" role="alert">
          提示：没有白名单的情况下将视为首次绑定，首次绑定后开始计时套餐费用，请不要连续点击避免有卡密失效的风险，希望大家遵守使用规则
        </div>
        <div class="alert alert-danger" role="alert">
          警告：<br>
          —— 允许换绑 但不允许多人共享 发现更换次数过多将<b>删除卡密</b>并不给予退款 每次更换都存在记录。 <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'queryReBind',
  data() {
    return {
      rebind_button: '绑定/换绑',
      rebind_cdk: '',
      rebind_id: '',
      rebind_load: true
    }
  },
  methods: {
    requery: function () {
      this.rebind_load = false;
      const axios = require('axios');
      // 向给定ID的用户发起请求
      axios.get(`https://xg.jsip.cc/rebind.php?cdk=${this.rebind_cdk}&MinecraftID=${this.rebind_id}`)
        .then(response => {
          if (response.data.type != '') {
            window.alert(response.data.string);
            this.rebind_load = true;
            if (this.$cookies.get('bindKey') == null) {
              this.$cookies.set('bindKey', this.rebind_cdk, '360h'); // 保存查询卡密到bindKey
              alert("首次绑定，正在换绑，已将你的记录存入到了浏览器，15天内不需要输入卡密");
              location.reload()
              this.$router.go(0)
            }
          } else {
            window.alert("我趣，这是一个要了命的错误，程序丢失了妈妈 请联系棱镜找回。");
            this.rebind_load = true;
          }
        })
        .catch(function (error) {
          window.alert(`未知错误，请联系棱镜，${error}`);
        })
    },
    deleteKey: function () {
      this.$cookies.remove('bindKey');
      location.reload();
      this.$router.go(0);
    }
  },
  mounted: function () {
    if (this.$cookies.get('bindKey') != null) {
      this.rebind_cdk = this.$cookies.get('bindKey');
    }
  }

}
</script>

<style >

</style>