<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" class="bg-dark shadow" type="dark" variant="info">
        <b-navbar-brand href="#"><img src="./assets/logo.png" width="40px" />雪糕加速IP - 用户中心</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <router-link to="/bind" active-class="active" style="text-decoration:none;"
              class="nav-link">查询线路</router-link>
            <router-link to="/ReBind" active-class="active" style="text-decoration:none;"
              class="nav-link">绑定/换绑</router-link>
            <router-link to="/keyCombine" active-class="active" style="text-decoration:none;"
              class="nav-link">合并卡密</router-link>
            <router-link to="/Admin" v-if="admin_button" active-class="active" style="text-decoration:none;"
              class="nav-link">管理员控制台</router-link>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <!-- 路由 -->
    <div class="container" style="padding:40px 20px 0px 20px;">
      <router-view class="animate__animated animate__fadeIn "></router-view>
    </div>

  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      admin_button: false // 管理员按钮
    }
  },
  mounted: function () {
    if (this.$cookies.get('admin_login') != null) {
      this.admin_button = true
    }
  }
}
</script>

<style>

</style>
