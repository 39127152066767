<template>
  <div class="text-white" style="margin-bottom:400px;">
    <div class="row">

      <!-- 登陆钥匙 -->
      <div class="col-xl-12" v-if="invisible_login">
        <div class="alert alert-danger" role="alert" style="padding:15px;">
          你正在访问一个迷宫，请插入钥匙：
          <div class="form-group" style="margin-top:10px;">
            <input type="password" class="form-control" v-model="admin_login" aria-describedby="text">
          </div>
          <button type="submit" @click="setAdminCookie" style="width:100%;" class="btn btn-danger">登陆</button>
        </div>
      </div>

      <!-- 导航栏 -->


      <!-- 添加卡密 -->
      <div class="col-xl-12" id="nav_addkey">
        <div class=" bg-dark" role="alert" style="padding:15px;">
          生成卡密：
          <hr class="text-info">
          <div class="form-group" style="margin-top:10px;">
            <textarea type="email" class="form-control " style="height:160px;" v-model="admin_key_info"
              aria-describedby="text" placeholder="准备生成的卡密信息（不需要填内容）"></textarea>
            <!-- 单选 -->
            <div style="padding:5px 5px 5px 5px;" class="float-left">
              <div class="form-check form-check-inline" @click="admin_key_time = 31">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                <label class="form-check-label" for="inlineRadio1">31天/月卡</label>
              </div>
              <div class="form-check form-check-inline" @click="admin_key_time = 93">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                <label class="form-check-label" for="inlineRadio2">93天/季卡</label>
              </div>
              <div class="form-check form-check-inline" @click="admin_key_time = 365">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
                <label class="form-check-label" for="inlineRadio3">365天/年卡</label>
              </div>

              <div class="form-check form-check-inline" @click="admin_key_time = ''">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4">
                <label class="form-check-label" for="inlineRadio4" style="width:180px;">自定义天数</label>
                <div class="input-group flex-nowrap">
                  <input type="text" class="form-control" v-model="admin_key_time" style="width:10px;" placeholder="请输入天数"
                    aria-label="Username" aria-describedby="addon-wrapping">
                </div>
              </div>
              <!-- 套餐选择 -->
              <div class="float-right">
                <b-dropdown id="dr1" :text="admin_key_menu">
                  <b-dropdown-item v-for="index in admin_type_list" :key="index.name" @click="admin_key_menu = index.type">{{ index.name }}</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

          </div>
          <button type="submit" @click="adminAddKey(1)" style="width:49.5%; margin-right: 0.5%;"
            class="btn btn-secondary">生成1条卡密</button>
          <button type="submit" @click="adminAddKey(10)" style="width:49.5%; margin-left: 0.5%;"
            class="btn btn-secondary">生成10条卡密</button>
        </div>
      </div>

      <!-- 自定义卡密 -->
      <div class="col-xl-12" id="nav_customkey">
        <div class="bg-dark" role="alert" style="padding:15px; margin-top:15px;">
          自定义卡密：
          <hr class="text-info">
          <div class="form-group" style="margin-top:10px;">
            <input type="text" class="form-control " style="width:100%" v-model="admin_custom_key" aria-describedby="text"
              placeholder="准备生成的卡密信息（不需要填内容）">
            <!-- 时间和代号 -->
            <div class="form-row" style="margin-top:10px;">
              <div class="col">
                <input type="text" v-model="admin_custom_time" class="form-control" placeholder="天数">
              </div>
              <div class="col">
                <input type="text" v-model="admin_custom_linename" class="form-control" placeholder="节点名">
              </div>
            </div>
            <input type="text" style="margin-top:10px;" v-model="admin_custom_ip" class="form-control"
              placeholder="返回的IP地址">
          </div>
          <button type="submit" @click="adminCustomKey()" style="width:100%;" class="btn btn-secondary">生成1条卡密</button>
        </div>
      </div>


      <!-- 超级搜索 -->
      <div class="col-xl-12" id="nav_search">
        <div class="bg-dark" role="alert" style="padding:15px; margin-top:15px;">
          超级搜索：
          <hr class="text-info">
          <div class="form-group" style="margin-top:10px;">
            <input type="text" class="form-control " style="width:100%" v-model="admin_search_key" aria-describedby="text"
              placeholder="输入卡密">
          </div>
          <button type="submit" @click="superSearch()" style="width:100%; margin-bottom: 15px;"
            class="btn btn-secondary">查询该卡密所有信息</button>

          <div>
            QQ：{{ admin_search_content.qq }} <br />
            是否可以使用：{{ admin_search_content.use }} <br />
            套餐时间：{{ admin_search_content.time }} <br />
            最后一次修改ID的时间：{{ admin_search_content.log }} <br />
            绑定过的ID：{{ admin_search_content.id }} <br>
            该卡密是否在白名单：{{ admin_search_content.whitelist }}

          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminCommand',
  data() {
    return {
      admin_login: '',
      admin_type_list: [
        // {
        //   "type": "SH-JP-IPLC-01",
        //   "name": "沪/京/广日IPLC / SH-JP-IPLC-01"
        // },
        // {
        //   "type": "SH-JP-IEPL-01",
        //   "name": "沪日IEPL / SH-JP-IEPL-01"
        // },
        // {
        //   "type": "SZ-JP-IPLC-01",
        //   "name": "苏日IPLC / SZ-JP-IPLC-01"
        // },
        // {
        //   "type": "SHBGP-US-IPLC-01",
        //   "name": "沪美BGP / SHBGP-US-IPLC-01"
        // },
        // {
        //   "type": "SHCM-US-IPLC-01",
        //   "name": "沪美移动 / SHCM-US-IPLC-01"
        // },
        // {
        //   "type": "SH-US-IEPL-01",
        //   "name": "沪美IEPL / SH-US-IEPL-01"
        // },
        // {
        //   "type": "minemen-01",
        //   "name": "Minemen / minemen-01"
        // },
        // {
        //   "type": "gzbgp-US-IPLC-01",
        //   "name": "广美IPLC / gzbgp-US-IPLC-01"
        // },
        // {
        //   "type": "SH-US-IPLC-01",
        //   "name": "沪美IPLC / SH-US-IPLC-01"
        // },
        // {
        //   "type": "SH-JP-IPLC-PRO",
        //   "name": "沪日IPLC PRO / SH-JP-IPLC-PRO"
        // }
      ],

      admin_key_info: '',
      admin_key_menu: '未选择套餐',
      admin_key_time: '',
      invisible_login: true,

      admin_custom_key: '',
      admin_custom_time: '',
      admin_custom_linename: '',
      admin_custom_ip: '',

      admin_search_key: '',
      // qq 使用状态 套餐时间 套餐线路 最后一次修改的时间 绑定过的ID 是否在白名单
      admin_search_content: {
        'qq': '',
        'use': '',
        'time': '',
        'log': '',
        'id': '',
        'whitelist': '',
      },
    }
  },
  methods: {
    adminAddKey: function (admin_Number) {  // 生成卡密
      const axios = require('axios');
      // 向给定ID的用户发起请求
      axios.get(`https://xg.jsip.cc/admin.php?admin_login=${this.admin_login}&type=addKey&addKey_Number=${admin_Number}&addKey_Time=${this.admin_key_time}&addKey_LineName=${this.admin_key_menu}`)
        .then(response => {
          this.admin_key_info = response.data;
        })
        .catch(function (error) {
          window.alert(`未知错误，请联系棱镜，${error}`);
        })
    },
    adminCustomKey: function () {   // 自定义卡密
      const axios = require('axios');
      // 向给定ID的用户发起请求
      axios.get(`https://xg.jsip.cc/admin.php?admin_login=${this.admin_login}&type=custom&custom_time=${this.admin_custom_time}&custom_linename=${this.admin_custom_linename}&custom_ip=${this.admin_custom_ip}`)
        .then(response => {
          this.admin_custom_key = response.data;
        })
        .catch(function (error) {
          window.alert(`未知错误，请联系棱镜，${error}`);
        })
    },
    superSearch: function () {   // 超级搜索
      const axios = require('axios');
      // 向给定ID的用户发起请求
      axios.get(`https://xg.jsip.cc/admin.php?admin_login=${this.admin_login}&type=search&search_key=${this.admin_search_key}`)
        .then(response => {
          this.admin_search_content = response.data;
        })
        .catch(function (error) {
          window.alert(`未知错误，请联系棱镜，${error}`);
        })
    },
    setAdminCookie: function () {   // 设置Cookie
      this.$cookies.set('admin_login', this.admin_login, "3600h");
      window.alert("保存完毕");
    }
  },
  mounted: function () {
    if (this.$cookies.get('admin_login') != null) {
      this.admin_login = this.$cookies.get('admin_login');
      this.invisible_login = false; // 隐藏登陆框
      const axios = require('axios');
      axios.get(`https://xg.jsip.cc/typelist.json`)
        .then(response => {
          this.admin_type_list = response.data;
        })
        .catch(function (error) {
          window.alert(`获取类型列表错误，${error}`);
        })
    }
  }

}
</script>

<style ></style>