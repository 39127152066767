<template>
  <div>

    <div class="row">
      <div class="col-xl-12">
        <div class="form-group">
          <label class="text-white" style="">卡密：</label>
          <input v-model="bind_input" type="password" class="form-control" maxlength="30"
            placeholder="请输入30位卡密">
        </div>
      </div>
      <div class="col-xl-12">
        <button class="btn btn-primary" v-if="bind_button == true" @click="query" style="width:100%;" type="submit">查询线路</button>
      </div>

      <div class="col-xl-12"  v-if="bind_button == false" style="display:grid; place-items: center; color:#fff;" >
        <div class="spinner-border" style="" role="status">
          <span class="sr-only" >Loading...</span>
        </div>
      </div>

      <div class="col-xl-12 animate__rubberBand" v-if="bind_se">
        <ul class="list-group" style="margin-top: 20px;">
          <li class="list-group-item list-group-item-success" aria-current="true">查询完毕！</li>
          <li class="list-group-item">绑定的QQ：{{ bind_qq }}</li>
          <li class="list-group-item">绑定的UUID：{{ bind_uuid }}</li>
          <li class="list-group-item">到期时间；{{ bind_time }}</li>
          <li class="list-group-item">线路：{{ bind_line }}</li>
          <li class="list-group-item">加速IP地址：{{ bind_ip }}</li>
        </ul>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'queryBind',
  data() {
    return {
      bind_se: false,
      bind_input: '',
      bind_qq: '...',
      bind_uuid: '...',
      bind_ip: '...',
      bind_time: '...',
      bind_line: '...',
      bind_button: true
    }
  },
  methods: {
    query: function () {
      this.bind_button = false;
      const axios = require('axios');
      // 向给定ID的用户发起请求
      axios.get(`https://xg.jsip.cc/bind.php?cdk=${this.bind_input}`)
        .then(response => {

          if (response.data.type == 'error') {
            window.alert(response.data.string);
            this.bind_button = true;
          } else {
            if (this.$cookies.get('bindKey') == null) {
              this.$cookies.set('bindKey', this.bind_input, '360h'); // 保存查询卡密到bindKey
              alert("首次绑定，正在查询，已将你的记录存入到了浏览器，15天内不需要输入卡密");
            }
            this.bind_qq = response.data.qq;
            this.bind_uuid = response.data.uuid;
            this.bind_ip = response.data.ip;
            this.bind_time = response.data.time;
            this.bind_line = response.data.line;
            this.bind_se = true;
            this.bind_button = true;
          }
        })
        .catch(function (error) {
          window.alert(`未知错误，请联系棱镜，${error}`);
        })

    }
  },
  mounted: function () {
    if (this.$cookies.get('bindKey') != null) {
      this.bind_input = this.$cookies.get('bindKey');
    }
  }
}
</script>

<style>

</style>
